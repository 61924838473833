import { SecondaryCta } from '@rsa-digital/evo-shared-components/components/Cta';
import ErrorContainer from '@rsa-digital/evo-shared-components/components/Form/Field/ErrorContainer';
import {
  defaultContainerId,
  defaultErrorId,
} from '@rsa-digital/evo-shared-components/components/Form/idHelpers';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler } from 'react';
import RichTextWithModal from 'components/RichTextWithModal';
import { trackButtonClick } from 'helpers/eventTracking';
import { GridItemWithTopMargin, Heading, HiddenLabel, StyledGrid } from './styles';
import { VehicleRegistrationMissingDetails } from '../validation';

type VehicleRegistrationRequiredSectionContent = {
  csCheckYourDetails: {
    vehicle_registration_required_section: {
      heading: string;
      body: string;
      enter_car_registration_cta: {
        display_text: string;
        screen_reader_text: string;
      };
    };
  };
};

const query = graphql`
  query {
    csCheckYourDetails {
      vehicle_registration_required_section {
        heading
        body
        enter_car_registration_cta {
          display_text
          screen_reader_text
        }
      }
    }
  }
`;

type VehicleRegistrationRequiredSectionProps = {
  onCtaClick: MouseEventHandler;
  getError: FieldFunction<VehicleRegistrationMissingDetails, string | undefined>;
} & ComponentProps;

const MtaVehicleRegistrationRequiredSection: React.FC<VehicleRegistrationRequiredSectionProps> = ({
  onCtaClick,
  getError,
  ...props
}) => {
  const {
    csCheckYourDetails: { vehicle_registration_required_section },
  } = useStaticQuery<VehicleRegistrationRequiredSectionContent>(query);

  const cta = vehicle_registration_required_section.enter_car_registration_cta;
  const baseId = 'isVehicleRegistrationMissing';

  return (
    <StyledGrid
      as="section"
      aria-labelledby="vehicle-registration-required-section-heading"
      {...componentProps(props)}>
      <GridItem desktop={10} tabletLandscape={10}>
        <Heading id="vehicle-registration-required-section-heading">
          {vehicle_registration_required_section.heading}
        </Heading>
        <RichTextWithModal html={vehicle_registration_required_section.body} />
      </GridItem>
      <GridItemWithTopMargin>
        <ErrorContainer
          errorText={getError(baseId)}
          id={defaultContainerId(baseId)}
          errorId={defaultErrorId(baseId)}>
          <HiddenLabel htmlFor={baseId} />
          <Grid>
            <GridItem desktop={10} tabletLandscape={10}>
              <SecondaryCta
                id={baseId}
                cta={{
                  onClick: (e) => {
                    trackButtonClick('goToVehicleDetailsCta', cta.display_text);
                    onCtaClick(e);
                  },
                  displayText: cta.display_text,
                  screenReaderText: cta.screen_reader_text,
                }}
              />
            </GridItem>
          </Grid>
        </ErrorContainer>
      </GridItemWithTopMargin>
    </StyledGrid>
  );
};

export default MtaVehicleRegistrationRequiredSection;

import React from 'react';
import { formatInPoundsAndPenceWithSign } from 'helpers/poundsHelpers';
import {
  NoCostText,
  PaymentText,
  PriceHeader,
  RefundAboveText,
  RefundBelowText,
  SummaryDetails,
} from './styles';
import { NoCostSummary, PaymentSummary, RefundSummary } from '../types';

type PriceSummaryProps = {
  paymentAmount: number;
};

const PaymentSummaryText: React.FC<PaymentSummary & PriceSummaryProps> = ({
  description,
  details,
  paymentAmount,
}) => (
  <>
    <PaymentText>{description}</PaymentText>
    <PriceHeader>{formatInPoundsAndPenceWithSign(paymentAmount)}</PriceHeader>
    <SummaryDetails html={details} />
  </>
);

const NoCostSummaryText: React.FC<NoCostSummary> = ({ heading, details }) => (
  <>
    <PriceHeader>{heading}</PriceHeader>
    <NoCostText>{details}</NoCostText>
  </>
);

const RefundSummaryText: React.FC<RefundSummary & PriceSummaryProps> = ({
  description_above,
  description_below,
  details,
  paymentAmount,
}) => (
  <>
    <RefundAboveText>{description_above}</RefundAboveText>
    <PriceHeader>{formatInPoundsAndPenceWithSign(-paymentAmount)}</PriceHeader>
    <RefundBelowText>{description_below}</RefundBelowText>
    <SummaryDetails html={details} />
  </>
);

type PriceChangeSummaryProps = PriceSummaryProps & {
  paymentSummary: PaymentSummary;
  refundSummary: RefundSummary;
  noCostSummary: NoCostSummary;
};

const PriceChangeSummary: React.FC<PriceChangeSummaryProps> = ({
  paymentSummary,
  refundSummary,
  noCostSummary,
  paymentAmount,
}) => (
  <>
    {paymentAmount > 0 && (
      <PaymentSummaryText {...paymentSummary} paymentAmount={paymentAmount} />
    )}
    {paymentAmount === 0 && <NoCostSummaryText {...noCostSummary} />}
    {paymentAmount < 0 && (
      <RefundSummaryText {...refundSummary} paymentAmount={paymentAmount} />
    )}
  </>
);

export default PriceChangeSummary;

import { screenReaderOnlyStyle } from '@rsa-digital/evo-shared-components/commonStyles/screenReaderOnly';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledGrid = styled(Grid)`
  & {
    margin-top: ${spacing(6)};
    margin-bottom: ${spacing(6)};
  }
`;

export const Heading = styled.h2`
  ${fonts.headingMedium};

  margin: 0 0 ${spacing(3)};
`;

export const GridItemWithTopMargin = styled(GridItem)`
  margin-top: ${spacing(4)};
`;

export const HiddenLabel = styled.label`
  ${screenReaderOnlyStyle}
  visibility: hidden;
`;

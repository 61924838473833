import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledGridItem = styled(GridItem)`
  display: flex;
  justify-content: flex-end;
`;

export const SectionHeading = styled.h2`
  ${fonts.headingMedium};
  margin-bottom: ${spacing(-3)};
  text-align: center;

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(-1)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(-2)};
    margin-top: ${spacing(8)};
  `}
`;

import { graphql, useStaticQuery } from 'gatsby';
import { useTheme } from 'styled-components';
import { DeclarationDetails } from 'components/Declaration';
import useCheckAdditionalDriversDetails from 'components/PolicyDetails/AdditionalDriverDetails/data';
import useCheckCarDetails from 'components/PolicyDetails/CarDetails/data';
import useExcessTableData from 'components/PolicyDetails/Excesses/data';
import useCheckPolicyholderDetails from 'components/PolicyDetails/PolicyholderDetails/data';
import { MtaQuote } from 'state/mta/mtaQuote';
import { MtaCheckYourDetailsPdfContent } from './types';
import { useCheckYourDetailsPdfContent } from '../hook';

const query = graphql`
  query {
    csMtaCheckDetails {
      hero {
        heading
        subheading
      }
      summary_section {
        no_cost_summary {
          details
          heading
        }
        payment_summary {
          description
          details
        }
        refund_summary {
          description_above
          description_below
          details
        }
        start_date_label
        policy_end_label
      }
      monthly_payment_info_banner {
        heading
        body
      }
      declaration {
        main_information_comprehensive
        main_information_comprehensive_monthly
        main_information_third_party_fire_theft
        main_information_third_party_fire_theft_monthly
        question {
          question_text
        }
      }
    }
  }
`;

export const useMtaCheckYourDetailsPdf = (
  mtaQuote: MtaQuote | null,
  declarationDetails: DeclarationDetails
): (() => Promise<void>) => {
  const theme = useTheme();

  const baseContent = useCheckYourDetailsPdfContent();
  const mtaContent = useStaticQuery<MtaCheckYourDetailsPdfContent>(query);
  const content = { ...baseContent, ...mtaContent };

  const carDetails = useCheckCarDetails(mtaQuote);
  const policyholderDetails = useCheckPolicyholderDetails(mtaQuote);
  const additionalDriversDetails = useCheckAdditionalDriversDetails(mtaQuote);
  const excessTableData = useExcessTableData(mtaQuote);

  if (!mtaQuote) {
    return () => Promise.resolve();
  }

  const props = {
    mtaQuote,
    declarationDetails,
    content,
    carDetails,
    policyholderDetails,
    additionalDriversDetails,
    excessTableData,
    theme,
  };

  return async () => {
    // As the PDF library is large, we use a dynamic import to ensure we only load this when
    // it is needed. The webpackChunkName comment is used to give the chunk a nice name.
    const { generateMtaCheckYourDetailsPdf } = await import(
      /* webpackChunkName: "check-details-pdf" */ '.'
    );
    return generateMtaCheckYourDetailsPdf(props);
  };
};

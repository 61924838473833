import { CallToAction } from '@rsa-digital/evo-shared-components/commonTypes/links';
import Modal from '@rsa-digital/evo-shared-components/components/Modal';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React, { MouseEventHandler } from 'react';
import {
  CancelActionLink,
  ConfirmCta,
  CtaContainer,
  ModalBody,
  ModalHeading,
  ModalWrapper,
} from './styles';

export type ConfirmationModalProps = {
  heading: string;
  body?: string;
  confirmCta: CallToAction;
  onClose: MouseEventHandler;
  cancelActionLink?: {
    displayText: string;
    screenReaderText: string;
  };
} & ComponentProps;

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  heading,
  body,
  confirmCta,
  onClose,
  cancelActionLink,
  ...props
}) => (
  <Modal
    {...componentProps(props)}
    onClose={onClose}
    aria-labelledby="confirmation-modal-heading">
    <ModalWrapper>
      <ModalHeading id="confirmation-modal-heading">{heading}</ModalHeading>
      {body && <ModalBody html={body} />}
      <CtaContainer>
        <ConfirmCta cta={confirmCta} />
        {cancelActionLink && (
          <CancelActionLink
            onClick={onClose}
            aria-label={cancelActionLink.screenReaderText}>
            {cancelActionLink.displayText}
          </CancelActionLink>
        )}
      </CtaContainer>
    </ModalWrapper>
  </Modal>
);

export default ConfirmationModal;

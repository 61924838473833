import { graphql, useStaticQuery } from 'gatsby';
import { useTheme } from 'styled-components';
import useCheckAdditionalDriversDetails from 'components/PolicyDetails/AdditionalDriverDetails/data';
import useCheckCarDetails from 'components/PolicyDetails/CarDetails/data';
import useExcessTableData from 'components/PolicyDetails/Excesses/data';
import useCheckPolicyholderDetails from 'components/PolicyDetails/PolicyholderDetails/data';
import { FullPreferenceDetails } from 'pages/quote/check-your-details';
import { Quote } from 'state/quote/quote';
import { CheckYourDetailsPdfContent } from './types';

const query = graphql`
  query {
    csCheckYourDetails {
      hero {
        heading
        subheading
      }
      hero_renewal {
        heading
        subheading
      }
      next_button_text
      your_car {
        heading
      }
      your_drivers {
        heading
      }
      your_policy {
        heading
        optional_extras_heading
      }
      your_excess {
        heading
      }
      documents {
        heading
        description
        radio_button_text {
          audio_cd
          braille
          large_print
          online
          online_and_other_formats
          paper
        }
        format {
          question_text
        }
      }
      keeping_you_informed {
        description
        extra_information
        heading
        question
      }
      declaration {
        heading
        question {
          question_text
        }
        information {
          main_section_comprehensive
          main_section_comprehensive_monthly
          main_section_third_party_fire_theft
          main_section_third_party_fire_theft_monthly
          main_section_renewal_comprehensive
          main_section_renewal_comprehensive_monthly
          main_section_renewal_third_party_fire_theft
          main_section_renewal_third_party_fire_theft_monthly
          endorsements {
            heading
            security_tracker
            driving_other_cars
          }
        }
      }
    }
    csPolicyDetails {
      policy_details {
        comprehensive
        third_party_fire_and_theft
        cover_start_date_label
        cover_renewal_date_label
      }
      optional_extras {
        included_title
        not_included_title
      }
      excess {
        fire_and_theft_excess_text
      }
      drivers_details {
        main_driver_suffix
        information_labels_policyholder {
          email_address
          telephone
        }
      }
    }
    allCsAddOn {
      nodes {
        type
        heading
        post_selection_summary
      }
    }
    csAddOnsConfig {
      monthly_subheading
      yearly_subheading
    }
    csAddOnBreakdownCover {
      heading
      monthly_subheading
      yearly_subheading
      post_selection_summary
      cover_options {
        roadside {
          selection {
            heading
          }
          confirmation {
            subheading
          }
        }
        home {
          selection {
            heading
          }
          confirmation {
            subheading
          }
        }
        recovery {
          selection {
            heading
          }
          confirmation {
            subheading
          }
        }
        europe {
          selection {
            heading
          }
          confirmation {
            subheading
          }
        }
      }
    }
    csExcesses {
      labels {
        compulsory_label
        voluntary_label
        total_label
      }
      accidental_damage_heading
      other_excesses_heading
      additional_info
    }
    csPdfTemplate {
      logo {
        ...CsAsset
      }
    }
  }
`;

export const useCheckYourDetailsPdfContent = (): CheckYourDetailsPdfContent =>
  useStaticQuery<CheckYourDetailsPdfContent>(query);

export const useCheckYourDetailsPdf = (
  quote: Quote | null,
  preferences: FullPreferenceDetails
): (() => Promise<void>) => {
  const theme = useTheme();
  const content = useCheckYourDetailsPdfContent();

  const carDetails = useCheckCarDetails(quote);
  const policyholderDetails = useCheckPolicyholderDetails(quote);
  const additionalDriversDetails = useCheckAdditionalDriversDetails(quote);
  const excessTableData = useExcessTableData(quote);

  if (!quote) {
    return () => Promise.resolve();
  }

  const props = {
    quote,
    preferences,
    content,
    carDetails,
    policyholderDetails,
    additionalDriversDetails,
    excessTableData,
    theme,
  };

  return async () => {
    // As the PDF library is large, we use a dynamic import to ensure we only load this when
    // it is needed. The webpackChunkName comment is used to give the chunk a nice name.
    const { generateCheckYourDetailsPdf } = await import(
      /* webpackChunkName: "check-details-pdf" */ '.'
    );
    return generateCheckYourDetailsPdf(props);
  };
};

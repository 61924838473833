import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import Image from '@rsa-digital/evo-shared-components/components/Image';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const GridWithMargin = styled(Grid)`
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(8)};
  `}
`;

export const SummaryWrapper = styled.div`
  margin-right: ${spacing(0)};
  text-align: center;

  ${mediaQuery.tabletLandscape`
    margin-right: ${spacing(2)};
  `}
`;

export const SummaryDivider = styled.div`
  display: none;
  margin: 0 -1px;
  height: auto;
  width: 2px;
  background: ${colors.neutral04};

  ${mediaQuery.tabletLandscape`
    display: block;
  `}
`;

export const TopDivider = styled.div`
  margin-bottom: ${spacing(2)};
  height: 1px;
  width: 100%;
  background: ${colors.neutral04};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(3)};
  `}

  ${mediaQuery.tabletLandscape`
    display: none;
  `}
`;

export const BottomDivider = styled.div`
  margin-top: ${spacing(2)};
  height: 1px;
  width: 100%;
  background: ${colors.neutral04};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(3)};
  `}

  ${mediaQuery.tabletLandscape`
    display: none;
  `}
`;

export const DatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(4)};
    flex-direction: row;
  `}
`;

export const DateDetailsWrapper = styled.div`
  :not(:first-child) {
    margin-top: ${spacing(2)};
  }

  ${mediaQuery.tabletLandscape`
    :not(:first-child) {
      margin-top: 0;
      margin-left: ${spacing(4)};
    }
    text-align: left;
  `}
`;

export const DateDetailsLabel = styled.p`
  margin: 0;
  ${fonts.paragraph}

  ${mediaQuery.tabletLandscape`
    ${fonts.paragraphSmall}
  `}
`;

export const DateDetailsDate = styled.p`
  margin: ${spacing(1)} 0 0;
  ${fonts.paragraph}
  & {
    font-weight: normal;
  }
`;

export const AdditionalInfoText = styled(RichTextWithModal)`
  margin-top: ${spacing(2)};
  text-align: center;
`;

export const ImageWrapper = styled.div`
  display: none;
  position: relative;
  height: 100%;

  ${mediaQuery.tabletLandscape`
    display: block;
  `}
`;

export const StyledImage = styled(Image)`
  /* This removes the image out of the page flow so that ImageWrapper can have the same height 
     as the other items in the flex box. Then we force the image to that height. */
  position: absolute;
  height: 100%;
`;

import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import Table from 'components/Table';
import { TableCellWithOptionalHeaderStyling } from 'components/Table/styles';

export const StyledTable = styled(Table)`
  margin-top: ${spacing(2)};
  table-layout: fixed;
  ${TableCellWithOptionalHeaderStyling} {
    text-align: left;
    vertical-align: top;
    word-wrap: break-word;
  }
`;

export const CreditAgreementWrapper = styled.div`
  margin: ${spacing(2)} ${spacing(1)} ${spacing(3)};
  ${mediaQuery.tabletPortrait`
    margin-left: ${spacing(4)};
    margin-right: ${spacing(4)};
  `}
  ${mediaQuery.tabletLandscape`
    margin: ${spacing(3)} ${spacing(8)} ${spacing(4)};
  `}
`;

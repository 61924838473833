import { graphql, useStaticQuery } from 'gatsby';
import { useTheme } from 'styled-components';
import useCheckAdditionalDriversDetails from 'components/PolicyDetails/AdditionalDriverDetails/data';
import useCheckCarDetails from 'components/PolicyDetails/CarDetails/data';
import useExcessTableData from 'components/PolicyDetails/Excesses/data';
import useCheckPolicyholderDetails from 'components/PolicyDetails/PolicyholderDetails/data';
import { FullPreferenceDetails } from 'pages/quote/check-your-details';
import { Quote } from 'state/quote/quote';
import { TieredCheckYourDetailsPdfContent } from './types';
import { useCheckYourDetailsPdfContent } from '../hook';

const query = graphql`
  query {
    csTieredCheckDetails {
      your_cover {
        heading
        essentials_description
        standard_description
        extra_description
        cover_labels {
          essentials
          standard
          extra
        }
      }
      your_optional_extras {
        included_in_extra_label
        additional_price_on_extra_annual_subheading
        additional_price_on_extra_monthly_subheading
      }
      declaration {
        information {
          main_section_tiered
          main_section_tiered_monthly
          main_section_tiered_renewal
          main_section_tiered_renewal_monthly
        }
      }
    }
  }
`;

export const useTieredCheckYourDetailsPdfContent = (): TieredCheckYourDetailsPdfContent =>
  useStaticQuery<TieredCheckYourDetailsPdfContent>(query);

export const useTieredCheckYourDetailsPdf = (
  quote: Quote | null,
  preferences: FullPreferenceDetails
): (() => Promise<void>) => {
  const theme = useTheme();

  const baseContent = useCheckYourDetailsPdfContent();
  const tieredContent = useTieredCheckYourDetailsPdfContent();
  const content = { ...baseContent, ...tieredContent };

  const carDetails = useCheckCarDetails(quote);
  const policyholderDetails = useCheckPolicyholderDetails(quote);
  const additionalDriversDetails = useCheckAdditionalDriversDetails(quote);
  const excessTableData = useExcessTableData(quote);

  if (!quote) {
    return () => Promise.resolve();
  }

  const props = {
    quote,
    preferences,
    content,
    carDetails,
    policyholderDetails,
    additionalDriversDetails,
    excessTableData,
    theme,
  };

  return async () => {
    // As the PDF library is large, we use a dynamic import to ensure we only load this when
    // it is needed. The webpackChunkName comment is used to give the chunk a nice name.
    const { generateTieredCheckYourDetailsPdf } = await import(
      /* webpackChunkName: "check-details-pdf" */ '.'
    );
    return generateTieredCheckYourDetailsPdf(props);
  };
};

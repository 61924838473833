import { Image as ImageType } from '@rsa-digital/evo-shared-components/commonTypes/image';
import {
  addDaysToDate,
  isSameOrLaterDateThan,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { MyAccountResult } from 'api/account/myAccountResult';
import { CsAccountTieredLogo } from 'components/PolicyCard/types';
import { TierOptions } from 'state/quote/quote';
import { isCurrentDateValidationDisabled } from './backdatedPolicyHelpers';
import { processImageAsset } from './csTypeProcessors';
import { MtaStatus } from '../state/mta/mtaQuote';
import { Policy, RenewalStatus } from '../state/policy/policy';

const swintonRenewalCutOff = process.env.GATSBY_MIGRATE_SWINTON_RENEWAL_CUTOFF;

export const isPolicyStartDateInFuture = (policy: Policy): boolean => {
  const policyStartDate = new Date(policy.effectiveFromDate);
  const tomorrow = addDaysToDate(new Date(), 1);

  return (
    !isCurrentDateValidationDisabled() &&
    !Number.isNaN(policyStartDate.getTime()) &&
    isSameOrLaterDateThan(policyStartDate, tomorrow)
  );
};

export const isPolicyReadonly = (policy: Policy): boolean =>
  policy.isInArrears ||
  isPolicyStartDateInFuture(policy) ||
  policy.mtaSummary?.mtaStatus === MtaStatus.Confirmed ||
  !!policy.renewalSummary?.renewalStatus;

export const canPolicyBeRenewed = (policy: Policy): boolean =>
  policy.renewalSummary?.renewalStatus === RenewalStatus.Ready && !policy.isInArrears;

export const isPolicyMigrating = (policy: Policy): boolean =>
  policy.renewalSummary?.renewalStatus === RenewalStatus.Pending &&
  isSameOrLaterDateThan(
    new Date(policy.renewalDate),
    new Date(swintonRenewalCutOff ?? '')
  );

export const getAccountName = (accountData: MyAccountResult): string =>
  accountData.quotes[0]?.account?.firstName ||
  accountData.policies[0]?.account?.firstName ||
  '';

export const isPolicyInactive = (policy: Policy): boolean =>
  (!isCurrentDateValidationDisabled() &&
    !isSameOrLaterDateThan(new Date(policy.expirationDate), new Date())) ||
  policy.status === 'Cancelled';

export const isPolicyActive = (policy: Policy): boolean =>
  (isCurrentDateValidationDisabled() ||
    isSameOrLaterDateThan(new Date(policy.expirationDate), new Date())) &&
  policy.status === 'InForce';

export const getPolicyTier = (policy: Policy): TierOptions | null | undefined =>
  policy.tieringInfo?.selectedTier;

export const getTierLogoForPolicy = (
  policy: Policy,
  content: CsAccountTieredLogo
): ImageType | undefined => {
  switch (getPolicyTier(policy)) {
    case TierOptions.Essentials:
      return processImageAsset(content.csTieredMyAccount.tier_logos.essentials_logo);
    case TierOptions.Standard:
      return processImageAsset(content.csTieredMyAccount.tier_logos.standard_logo);
    case TierOptions.Extra:
      return processImageAsset(content.csTieredMyAccount.tier_logos.extra_logo);
    default:
      return undefined;
  }
};

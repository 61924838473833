import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { isAnnualPayment, isComprehensiveCover } from 'api/businessLogic/quote';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { DeclarationDetails } from 'components/Declaration';
import { MtaQuote } from 'state/mta/mtaQuote';
import { isTieredQuote } from 'state/quote/quote';
import { DeclarationWithMargin } from './styles';

type DeclarationData = {
  csMtaCheckDetails: {
    declaration: {
      main_information_comprehensive: string;
      main_information_comprehensive_monthly: string;
      main_information_third_party_fire_theft: string;
      main_information_third_party_fire_theft_monthly: string;
      main_information_tiered: string;
      main_information_tiered_monthly: string;
      question: {
        question_text: string;
      };
    };
  };
};

const query = graphql`
  query {
    csMtaCheckDetails {
      declaration {
        main_information_comprehensive
        main_information_comprehensive_monthly
        main_information_third_party_fire_theft
        main_information_third_party_fire_theft_monthly
        main_information_tiered
        main_information_tiered_monthly
        question {
          question_text
        }
      }
    }
  }
`;

const getDeclarationMainSection = (
  mtaQuote: MtaQuote,
  content: DeclarationData['csMtaCheckDetails']
): string => {
  if (isTieredQuote(mtaQuote)) {
    return isAnnualPayment(mtaQuote)
      ? content.declaration.main_information_tiered
      : content.declaration.main_information_tiered_monthly;
  }
  if (isComprehensiveCover(mtaQuote.policy.coverWanted)) {
    return isAnnualPayment(mtaQuote)
      ? content.declaration.main_information_comprehensive
      : content.declaration.main_information_comprehensive_monthly;
  }

  return isAnnualPayment(mtaQuote)
    ? content.declaration.main_information_third_party_fire_theft
    : content.declaration.main_information_third_party_fire_theft_monthly;
};

type MtaDeclarationSectionProps = {
  mtaQuote: MtaQuote;
  hasAgreed: boolean | undefined;
  updateHasAgreed: (update: boolean) => void;
  getError: FieldFunction<DeclarationDetails, string | undefined>;
};

const MtaDeclarationSection: React.FC<MtaDeclarationSectionProps> = ({
  mtaQuote,
  hasAgreed,
  updateHasAgreed,
  getError,
}) => {
  const data = useStaticQuery<DeclarationData>(query).csMtaCheckDetails;

  return (
    <DeclarationWithMargin
      id="mta-declaration-panel"
      quote={mtaQuote}
      mainInformation={getDeclarationMainSection(mtaQuote, data)}
      hasAgreed={hasAgreed}
      updateHasAgreed={updateHasAgreed}
      questionText={data.declaration.question.question_text}
      getError={getError}
      headingsAs="h2"
    />
  );
};

export default MtaDeclarationSection;

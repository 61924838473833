import InfoBanner from '@rsa-digital/evo-shared-components/components/InfoBanner';
import { ActionLink } from '@rsa-digital/evo-shared-components/components/Link';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const InfoBannerHeading = styled.h2`
  ${fonts.headingSmall}
  margin: 0 0 ${spacing(2)};
`;

export const StyledActionLink = styled(ActionLink)`
  margin-top: ${spacing(2)};
`;

export const InfoBannerWithMargin = styled(InfoBanner)`
  && {
    margin-top: ${spacing(4)};
    margin-bottom: ${spacing(8)};

    ${mediaQuery.tabletPortrait`
      margin-top: ${spacing(3)};
    `}

    ${mediaQuery.tabletLandscape`
      margin-top: ${spacing(4)};
    `}
  }
`;

import { HeroPlain } from '@rsa-digital/evo-shared-components/components/Hero';
import Modal, { ModalProps } from '@rsa-digital/evo-shared-components/components/Modal';
import {
  addOrdinalSuffix,
  formatLongDateWithDayFirst,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import React, { MouseEventHandler } from 'react';
import { trackButtonClick } from 'helpers/eventTracking';
import { formatInPounds } from 'helpers/poundsHelpers';
import { MtaQuote } from 'state/mta/mtaQuote';
import { CreditAgreementWrapper, StyledTable } from './styles';

type MtaCreditAgreementModalProps = {
  onClose: MouseEventHandler;
  quote: MtaQuote;
} & ModalProps;

const MtaCreditAgreementModal: React.FC<MtaCreditAgreementModalProps> = ({
  onClose,
  quote,
}) => {
  const monthlyPaymentDetails = quote.paymentDetails.monthlyPayment;
  const annualPaymentTotal = quote.paymentDetails.annualPayment?.total ?? 0;
  const depositAmount = monthlyPaymentDetails?.deposit ?? 0;
  const creditAmount = formatInPounds(annualPaymentTotal - depositAmount);
  const numberOfInstallments =
    (monthlyPaymentDetails.installments?.dates?.length ?? 0) + 1;
  const dateOfMonthlyPayment = new Date(quote?.effectiveFromDate ?? 0).getUTCDate();
  const dateOfMonthlyPaymentWithSuffix = addOrdinalSuffix(dateOfMonthlyPayment);

  const heroHeading = 'Modifying PRE-CONTRACT CREDIT INFORMATION';
  const heroSubheading = `This document sets out the variations to your Credit Agreement relating to policy number ${quote.policyNumber}. 
  All other information in your previous Credit Agreement remains unchanged and applicable to your loan. The APR is detailed for information purposes only.`;

  const section1Table = [
    {
      cells: [
        {
          content:
            'Royal & Sun Alliance Insurance plc (“RSA”) St Marks Court, Chart Way, Horsham, West Sussex, RH12 1XL',
          colSpan: 5,
        },
      ],
    },
  ];

  const section2Table = [
    {
      cells: [
        {
          content: `The total amount of credit.

            This means the amount of credit to be provided under the proposed credit agreement or the credit limit.`,
          colSpan: 5,
        },
        {
          content: `£${creditAmount}`,
          colSpan: 5,
        },
      ],
    },
    {
      cells: [
        {
          content: 'Repayments.',
          colSpan: 5,
        },
        {
          content: `The total amount of credit is repayable by ${
            numberOfInstallments - 1
          } payments of £${formatInPounds(
            monthlyPaymentDetails.installments?.amount
          )} and a final payment of £${formatInPounds(
            monthlyPaymentDetails.finalInstallment?.amount
          )}. The first payment is due on ${
            quote.effectiveFromDate && formatLongDateWithDayFirst(quote.effectiveFromDate)
          } and your remaining monthly payments due on the ${dateOfMonthlyPaymentWithSuffix} of each consecutive month.`,
          colSpan: 5,
        },
      ],
    },
    {
      cells: [
        {
          content:
            '<p>The total amount you will have to pay.<br>This means the amount you have borrowed plus interest and other costs</p>',
          colSpan: 5,
        },
        {
          content: `£${formatInPounds(monthlyPaymentDetails.total)}`,
          colSpan: 5,
        },
      ],
    },
    {
      cells: [
        {
          content: 'Cash price',
          colSpan: 5,
        },
        {
          content: `The Cash Price of insurance policy number ${
            quote.policyNumber
          } is £${formatInPounds(annualPaymentTotal)}.`,
          colSpan: 5,
        },
      ],
    },
    {
      cells: [
        {
          content: 'The rates of interest which apply to the credit agreement.',
          colSpan: 5,
        },
        {
          content: `<p>Fixed interest rate of ${monthlyPaymentDetails.annualRateOfInterest}.</p>
            <p>This rate is fixed for the whole period of this agreement but may vary if you make a change to your insurance policy that affects the premium payable.</p>
            <p>We calculate at the outset the amount of interest payable on the total amount of credit by applying a rate of ${monthlyPaymentDetails.annualRateOfInterest} per annum. The interest is then payable, together with the total amount of credit in equal instalments over the term of the loan. The proportion of credit and interest within each monthly instalment will remain fixed over the term of the loan.</p>`,
          colSpan: 5,
        },
      ],
    },
    {
      cells: [
        {
          content: `<p>Annual Percentage Rate of Charge (APR).</p>
            <p>This is the total cost expressed as an annual percentage of the total amount of credit. The APR is there to help you compare different offers.</p>`,
          colSpan: 5,
        },
        {
          content: `<p>${monthlyPaymentDetails.aprVariable} (Fixed)</p>
          <p>This rate is fixed for this agreement but may vary if you make a change to your insurance policy that affects the premium payable.</p>`,
          colSpan: 5,
        },
      ],
    },
  ];

  return (
    <Modal
      data-cy="mta-credit-agreement-modal"
      aria-labelledby="modal-hero"
      onClose={(e) => {
        trackButtonClick('mtaPreContractCreditAgreement ', 'Close');
        onClose(e);
      }}>
      <CreditAgreementWrapper>
        <HeroPlain data-cy="modal-hero" heading={heroHeading} subhead={heroSubheading} />
        <StyledTable data-cy="pcca-modal-section-1-table" bodyRows={section1Table} />
        <StyledTable data-cy="pcca-modal-section-2-table" bodyRows={section2Table} />
      </CreditAgreementWrapper>
    </Modal>
  );
};

export default MtaCreditAgreementModal;

import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { formatLongDateWithDayFirst } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { applyComponentTheme } from '@rsa-digital/evo-shared-components/theme';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { MtaQuote } from 'state/mta/mtaQuote';
import PriceChangeSummary from './PriceChangeSummary';
import {
  AdditionalInfoText,
  BottomDivider,
  DateDetailsDate,
  DateDetailsLabel,
  DateDetailsWrapper,
  DatesContainer,
  GridWithMargin,
  ImageWrapper,
  StyledImage,
  SummaryDivider,
  SummaryWrapper,
  TopDivider,
} from './styles';
import { MtaQuoteSummarySectionContent } from './types';

export const query = graphql`
  query {
    csMtaCheckDetails {
      summary_section {
        no_cost_summary {
          details
          heading
        }
        payment_summary {
          description
          details
        }
        refund_summary {
          description_above
          description_below
          details
        }
        start_date_label
        policy_end_label
        image {
          ...CsAsset
        }
        change_date_info
      }
    }
  }
`;

type DateDetailsProps = {
  label: string;
  unformattedDate: string;
};

const DateDetails: React.FC<DateDetailsProps> = ({ label, unformattedDate }) => (
  <DateDetailsWrapper>
    <DateDetailsLabel>{label}</DateDetailsLabel>
    <DateDetailsDate>{formatLongDateWithDayFirst(unformattedDate)}</DateDetailsDate>
  </DateDetailsWrapper>
);

type MtaQuoteSummarySectionProps = {
  mtaQuote: MtaQuote;
};

const MtaQuoteSummarySection: React.FC<MtaQuoteSummarySectionProps> = ({ mtaQuote }) => {
  const { csMtaCheckDetails } = useStaticQuery<MtaQuoteSummarySectionContent>(query);
  const { summary_section } = csMtaCheckDetails;

  const image = processImageAsset(summary_section.image);
  return (
    <GridWithMargin as="section" aria-label="Mta quote summary">
      <GridItem desktop={10} tabletLandscape={10}>
        <Grid>
          <GridItem>
            <TopDivider />
          </GridItem>
          <GridItem desktop={5} tabletLandscape={5}>
            <SummaryWrapper>
              <PriceChangeSummary
                paymentSummary={summary_section.payment_summary}
                refundSummary={summary_section.refund_summary}
                noCostSummary={summary_section.no_cost_summary}
                paymentAmount={mtaQuote.mtaPaymentAmount}
              />
              <DatesContainer>
                <DateDetails
                  label={summary_section.start_date_label}
                  unformattedDate={mtaQuote.mtaEffectiveFromDate}
                />
                {mtaQuote.expirationDate && (
                  <DateDetails
                    label={summary_section.policy_end_label}
                    unformattedDate={mtaQuote.expirationDate}
                  />
                )}
              </DatesContainer>
            </SummaryWrapper>
          </GridItem>
          <SummaryDivider />
          <GridItem desktop={5} tabletLandscape={5}>
            {image && (
              <ImageWrapper>
                <StyledImage image={image} />
              </ImageWrapper>
            )}
          </GridItem>
          {summary_section.change_date_info && (
            <AdditionalInfoText html={summary_section.change_date_info} />
          )}
          <GridItem>
            <BottomDivider />
          </GridItem>
        </Grid>
      </GridItem>
    </GridWithMargin>
  );
};

export default applyComponentTheme(MtaQuoteSummarySection, 'MtaQuoteSummarySection');

import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';

type csVehicleRegistrationRequiredSectionErrorMessages = {
  csCheckYourDetails: {
    vehicle_registration_required_section: {
      error_message: string;
    };
  };
};

const query = graphql`
  query {
    csCheckYourDetails {
      vehicle_registration_required_section {
        error_message
      }
    }
  }
`;

export type VehicleRegistrationMissingDetails = {
  isVehicleRegistrationMissing: boolean;
};

const useVehicleRegistrationRequiredSectionRules = (): ValidationRules<
  VehicleRegistrationMissingDetails
> => {
  const errorMessages = useStaticQuery<csVehicleRegistrationRequiredSectionErrorMessages>(
    query
  ).csCheckYourDetails;

  return {
    isVehicleRegistrationMissing: [
      {
        validityCondition: (value) => !value,
        errorMessage: errorMessages.vehicle_registration_required_section.error_message,
        onlyValidateAfterSubmission: true,
      },
    ],
  };
};

export default useVehicleRegistrationRequiredSectionRules;

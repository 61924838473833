import { required } from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { DeclarationDetails } from 'components/Declaration';

type csKeepInformedErrorMessages = {
  csMtaCheckDetails: {
    declaration: {
      question: {
        question_text: string;
        error_messages: {
          declined: string;
          missing: string;
        };
      };
    };
  };
};

const query = graphql`
  query {
    csMtaCheckDetails {
      declaration {
        question {
          question_text
          error_messages {
            declined
            missing
          }
        }
      }
    }
  }
`;

const useMtaDeclarationRules = (): ValidationRules<DeclarationDetails> => {
  const errorMessages = useStaticQuery<csKeepInformedErrorMessages>(query)
    .csMtaCheckDetails.declaration.question;

  return {
    hasAgreedToDeclaration: [
      required(errorMessages.error_messages.missing),
      {
        validityCondition: (value) => value !== false,
        errorMessage: errorMessages.error_messages.declined,
      },
    ],
  };
};

export default useMtaDeclarationRules;

import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const PriceHeader = styled.p`
  margin: 0;
  ${fonts.headingLarge}
  & {
    color: ${colors.core01};
  }
`;

export const PaymentText = styled.p`
  margin: 0 0 ${spacing(1)};
  ${fonts.paragraph}
  & {
    font-weight: normal;
  }
`;

export const RefundAboveText = styled.p`
  margin: 0 0 ${spacing(2)};
  ${fonts.headingSmall}

  ${mediaQuery.tabletLandscape`
    margin-top: 0;
    ${fonts.headingXSmall}
  `}
`;

export const RefundBelowText = styled.p`
  margin: 0 0 ${spacing(2)};
  ${fonts.paragraph}
  & {
    font-weight: normal;
  }
`;

export const NoCostText = styled.p`
  margin: 0;
  ${fonts.paragraph}

  ${mediaQuery.tabletLandscape`
    ${fonts.paragraphSmall}
  `}
`;

export const SummaryDetails = styled(RichTextWithModal)`
  margin-top: 0;
  ${fonts.paragraphSmall}

  ${mediaQuery.tabletPortrait`
    ${fonts.paragraph}
  `}

  ${mediaQuery.tabletLandscape`
    ${fonts.paragraphSmall}
  `}
`;

import { defaultLabelId } from '@rsa-digital/evo-shared-components/components/Form/idHelpers';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import InfoBanner from '@rsa-digital/evo-shared-components/components/InfoBanner';
import { PanelOptions } from '@rsa-digital/evo-shared-components/components/Panel/PredefinedPanel/predefinedStyles';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import { trackButtonClick } from 'helpers/eventTracking';
import { MtaQuote } from 'state/mta/mtaQuote';
import { Quote } from 'state/quote/quote';
import {
  ContentWrapper,
  EndorsementsHeading,
  EndorsementsWrapper,
  InputWrapper,
  PanelContentSpacing,
  QuestionText,
  QuestionTextWrapper,
  StyledPredefinedPanel,
  StyledRichText,
} from './styles';

type EndorsementsContent = {
  csCheckYourDetails: {
    declaration: {
      information: {
        endorsements: {
          heading: string;
          security_tracker: string;
          driving_other_cars: string;
        };
      };
    };
  };
};

const query = graphql`
  query {
    csCheckYourDetails {
      declaration {
        information {
          endorsements {
            heading
            security_tracker
            driving_other_cars
          }
        }
      }
    }
  }
`;

export type DeclarationDetails = {
  hasAgreedToDeclaration: boolean | undefined;
};

type DeclarationProps = {
  quote: Quote | MtaQuote;
  mainInformation: string;
  hasAgreed: boolean | undefined;
  updateHasAgreed: (update: boolean) => void;
  questionText: string;
  getError: FieldFunction<DeclarationDetails, string | undefined>;
  headingsAs?: 'h2' | 'h3';
} & ComponentProps;

const Declaration: React.FC<DeclarationProps> = ({
  quote,
  mainInformation,
  hasAgreed,
  updateHasAgreed,
  questionText,
  getError,
  headingsAs,
  ...props
}) => {
  const endorsementsContent = useStaticQuery<EndorsementsContent>(query)
    .csCheckYourDetails.declaration.information.endorsements;
  const error = getError('hasAgreedToDeclaration');

  const hasEndorsements =
    quote.endorsements.hasDrivingOtherCars || quote.endorsements.hasSecurityTracker;

  return (
    <InfoBanner {...componentProps(props)}>
      <ContentWrapper>
        <StyledRichText
          html={mainInformation}
          onLinkClick={(label) => trackButtonClick('declarationRichTextLink', label)}
        />
        {hasEndorsements && (
          <EndorsementsWrapper useCustomBulletIcon={false} useOpenInNewTabIcons={false}>
            <EndorsementsHeading as={headingsAs}>
              {endorsementsContent.heading}
            </EndorsementsHeading>
            <ul>
              {quote.endorsements.hasSecurityTracker && (
                <li>
                  <StyledRichText
                    html={endorsementsContent.security_tracker}
                    onLinkClick={(label) =>
                      trackButtonClick('securityTrackerRichTextLink', label)
                    }
                  />
                </li>
              )}
              {quote.endorsements.hasDrivingOtherCars && (
                <li>
                  <StyledRichText
                    html={endorsementsContent.driving_other_cars}
                    onLinkClick={(label) =>
                      trackButtonClick('drivingOtherCarsRichTextLink', label)
                    }
                  />
                </li>
              )}
            </ul>
          </EndorsementsWrapper>
        )}
        <StyledPredefinedPanel
          id="declaration-confirmation-panel"
          panelType={error ? PanelOptions.ERROR : PanelOptions.NONE}
          bottomLabelText={error}>
          <PanelContentSpacing>
            <QuestionTextWrapper>
              <QuestionText
                id={defaultLabelId('hasAgreedToDeclaration')}
                htmlFor="hasAgreedToDeclaration">
                {questionText}
              </QuestionText>
            </QuestionTextWrapper>
            <InputWrapper>
              <Grid>
                <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
                  <BooleanRadioInput
                    id="hasAgreedToDeclaration"
                    value={hasAgreed}
                    onChange={(update) => {
                      updateHasAgreed(update);
                    }}
                    aria-describedby="declaration-confirmation-panel-bottom-label"
                  />
                </GridItem>
              </Grid>
            </InputWrapper>
          </PanelContentSpacing>
        </StyledPredefinedPanel>
      </ContentWrapper>
    </InfoBanner>
  );
};

export default Declaration;

import PredefinedPanel from '@rsa-digital/evo-shared-components/components/Panel/PredefinedPanel';
import { DefaultStyledRichTextWrapper } from '@rsa-digital/evo-shared-components/components/RichText/styles';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

const customRichTextStyling = css`
  h2,
  h3 {
    ${fonts.headingXSmall}
    margin: 0;
  }

  ul,
  ol {
    margin-top: ${spacing(1)};

    > li {
      p {
        margin: 0;
      }

      margin-top: 0;
      margin-bottom: ${spacing(1)};
    }
  }
`;

export const StyledRichText = styled(RichTextWithModal)`
  ${customRichTextStyling}
`;

export const EndorsementsWrapper = styled(DefaultStyledRichTextWrapper)`
  ${customRichTextStyling}

  margin-top: ${spacing(2)};
`;

export const EndorsementsHeading = styled.h3``;

export const ContentWrapper = styled.div`
  padding-bottom: ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    padding: 0 ${spacing(1)} ${spacing(1)};
  `};

  ${mediaQuery.tabletLandscape`
    padding: 0;
  `};
`;

export const StyledPredefinedPanel = styled(PredefinedPanel)`
  margin-top: ${spacing(4)};
  padding: 0;
`;

export const PanelContentSpacing = styled.div`
  margin: ${spacing(4)} ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(3)}
  `};

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(4)}
  `};
`;

export const QuestionTextWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin: ${spacing(1)} 0 ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin-top: 0;
  `}
`;

export const QuestionText = styled.label`
  ${fonts.paragraph}
  & {
    font-weight: normal;
  }

  display: inline-block;
  text-align: center;
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${mediaQuery.tabletPortrait`
    margin: 0 ${spacing(-7)};
  `}

  ${mediaQuery.tabletLandscape`
    margin: 0 ${spacing(-8)};
  `}
`;

import { graphql, useStaticQuery } from 'gatsby';
import React, { useReducer } from 'react';
import MtaCreditAgreementModal from 'components/Payment/PaymentSummarySection/PaymentSummaryTable/MtaCreditAgreementModal';
import RichTextWithModal from 'components/RichTextWithModal';
import { trackModalOpen } from 'helpers/eventTracking';
import replaceMtaQuotePlaceholders from 'helpers/replaceMtaQuotePlaceholders';
import { MtaQuote } from 'state/mta/mtaQuote';
import { InfoBannerHeading, InfoBannerWithMargin, StyledActionLink } from './styles';

export type MtaMonthlyPaymentInfoBannerContent = {
  csMtaCheckDetails: {
    monthly_payment_info_banner: {
      heading: string;
      body: string;
      credit_agreement_link: string;
    };
  };
};

export const query = graphql`
  query {
    csMtaCheckDetails {
      monthly_payment_info_banner {
        heading
        body
        credit_agreement_link
      }
    }
  }
`;

type MtaMonthlyPaymentInfoBannerProps = {
  mtaQuote: MtaQuote;
};

const MtaMonthlyPaymentInfoBanner: React.FC<MtaMonthlyPaymentInfoBannerProps> = ({
  mtaQuote,
}) => {
  const { csMtaCheckDetails } = useStaticQuery<MtaMonthlyPaymentInfoBannerContent>(query);
  const { monthly_payment_info_banner } = csMtaCheckDetails;

  const [displayCreditAgreementModal, toggleCreditAgreementPlanModal] = useReducer(
    (state) => !state,
    false
  );

  const infoBanner = replaceMtaQuotePlaceholders(monthly_payment_info_banner, mtaQuote);

  return (
    <InfoBannerWithMargin id="monthly-payment-info-banner">
      <InfoBannerHeading>{infoBanner.heading}</InfoBannerHeading>
      <RichTextWithModal html={infoBanner.body} />
      <StyledActionLink
        data-cy="mta-credit-agreement-link"
        onClick={() => {
          toggleCreditAgreementPlanModal();
          trackModalOpen('preContractCreditAgreement');
        }}>
        {infoBanner.credit_agreement_link}
      </StyledActionLink>
      {displayCreditAgreementModal && (
        <MtaCreditAgreementModal
          onClose={toggleCreditAgreementPlanModal}
          quote={mtaQuote}
        />
      )}
    </InfoBannerWithMargin>
  );
};

export default MtaMonthlyPaymentInfoBanner;

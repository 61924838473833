import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import Declaration from 'components/Declaration';

export const DeclarationWithMargin = styled(Declaration)`
  && {
    margin-top: ${spacing(4)};

    ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(8)};
  `}
  }
`;

import Accordion, {
  AccordionEntry,
} from '@rsa-digital/evo-shared-components/components/Accordion';
import { PrimaryCta } from '@rsa-digital/evo-shared-components/components/Cta';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { HeroPlain } from '@rsa-digital/evo-shared-components/components/Hero';
import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { isMtaSinglePaymentOnly } from 'api/businessLogic/mtaPayment';
import { isVehicleRegistrationMissing } from 'api/businessLogic/quote';
import mtaClient from 'api/mtaClient';
import policyClient from 'api/policyClient';
import { QuoteAdditionalDriverDetails } from 'api/quote/quoteRequest';
import { graphql, navigate } from 'gatsby';
import { useTieredCheckYourDetailsPdf } from 'pdf/CheckYourDetailsPdf/MtaCheckYourDetailsPdf/TieredMtaCheckYourDetailsPdf/hook';
import { useMtaCheckYourDetailsPdf } from 'pdf/CheckYourDetailsPdf/MtaCheckYourDetailsPdf/hook';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import LoadMtaQuoteWrapper from 'components/ApiRequestWrapper/LoadMtaQuoteWrapper';
import MtaVehicleRegistrationRequiredSection from 'components/CheckDetails/VehicleRegistrationRequiredSection/MtaVehicleRegistrationRequiredSection';
import useVehicleRegistrationRequiredSectionRules from 'components/CheckDetails/VehicleRegistrationRequiredSection/validation';
import YourCoverSection from 'components/CheckDetails/YourCoverSection';
import FormFooter from 'components/FormFooter';
import Layout from 'components/Layout';
import MtaDeclarationSection from 'components/MtaCheckDetails/MtaDeclarationSection';
import useMtaDeclarationRules from 'components/MtaCheckDetails/MtaDeclarationSection/validation';
import MtaMonthlyPaymentInfoBanner from 'components/MtaCheckDetails/MtaMonthlyPaymentInfoBanner';
import MtaQuoteSummarySection from 'components/MtaCheckDetails/MtaQuoteSummarySection';
import CarDetailsSection from 'components/MyPolicy/CarDetailsSection';
import ConfirmRemoveDriverModal from 'components/MyPolicy/ConfirmRemoveDriverModal';
import DriverDetailsSection from 'components/MyPolicy/DriverDetailsSection';
import ExcessSection from 'components/MyPolicy/ExcessSection';
import OptionalExtrasSection from 'components/MyPolicy/OptionalExtrasSection';
import PolicyDetailsSection from 'components/MyPolicy/PolicyDetailsSection';
import { trackPurchaseEvent, useCheckoutTracking } from 'helpers/ecommerceTracking';
import {
  trackAccordionCollapse,
  trackAccordionExpand,
  trackButtonClick,
  trackFieldError,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { isQuoteSummaryValid } from 'helpers/quoteSummaryValidation';
import { mtaRoutes } from 'helpers/routingHelper';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { usePageTracking } from 'helpers/usePageTracking';
import withPolicyNumber, { PolicyNumberProps } from 'helpers/withPolicyNumber';
import { initialAddDriverMtaDetails } from 'state/formData/mta/addDriver';
import { initialEditAddressMtaDetails } from 'state/formData/mta/editAddress';
import { initialVehicleDetails } from 'state/formData/vehicleDetails';
import { useMtaState } from 'state/mta/mta';
import { UPDATE_MTA_QUOTE, useMtaQuote } from 'state/mta/mtaQuote';
import { UPDATE_POLICY, usePolicyData } from 'state/policy/policy';
import useDispatch from 'state/useDispatch';
import { useUser } from 'state/user/state';
import { CsHero, CsMeta } from 'types/contentStack';
import { SectionHeading, StyledGridItem } from './styles';

type MtaCheckYourDetailsProps = {
  data: {
    csMtaCheckDetails: {
      meta: CsMeta;
      hero: CsHero;
      declaration: {
        cta_payment_change: {
          screenreader_text: string;
          text: string;
        };
        cta_no_payment_change: {
          screenreader_text: string;
          text: string;
        };
      };
    };
    csPolicyDetails: {
      policy_accordion_sections: {
        heading: string;
        policy_details: {
          heading: string;
        };
        optional_extras: {
          heading: string;
        };
        excess: {
          heading: string;
        };
        car_details: {
          heading: string;
        };
        driver_details: {
          heading: string;
        };
      };
    };
  };
};

export const query = graphql`
  query {
    csMtaCheckDetails {
      meta {
        meta_title
      }
      hero {
        heading
        subheading
      }
      declaration {
        cta_payment_change {
          screenreader_text
          text
        }
        cta_no_payment_change {
          screenreader_text
          text
        }
      }
    }
    csPolicyDetails {
      policy_accordion_sections {
        heading
        policy_details {
          heading
        }
        optional_extras {
          heading
        }
        excess {
          heading
        }
        car_details {
          heading
        }
        driver_details {
          heading
        }
      }
    }
  }
`;

const MtaCheckYourDetails: React.FC<MtaCheckYourDetailsProps & PolicyNumberProps> = ({
  data: {
    csMtaCheckDetails: {
      meta,
      hero,
      declaration: { cta_payment_change, cta_no_payment_change },
    },
    csPolicyDetails: {
      policy_accordion_sections: {
        heading,
        policy_details,
        optional_extras,
        excess,
        car_details,
        driver_details,
      },
    },
  },
  policyNumber,
}) => {
  const { isLoading, loadingMessage, requestHandler } = useApiRequestHandler();
  const mtaQuote = useMtaQuote();
  const vehicleRegistrationMissing =
    mtaQuote?.mtaType?.isChangeOfVehicle === true &&
    isVehicleRegistrationMissing(mtaQuote);
  const [, updateMtaState] = useMtaState();
  const [hasAgreedToDeclaration, setHasAgreedToDeclaration] = useState<boolean>();
  const dispatch = useDispatch();

  const rules = {
    ...useMtaDeclarationRules(),
    ...useVehicleRegistrationRequiredSectionRules(),
  };

  const { getError, validateOnSubmit, showValidation } = useValidation(
    {
      hasAgreedToDeclaration,
      isVehicleRegistrationMissing: vehicleRegistrationMissing,
    },
    rules,
    trackFieldError
  );

  const generatePdf = useMtaCheckYourDetailsPdf(mtaQuote, { hasAgreedToDeclaration });
  const generateTieredPdf = useTieredCheckYourDetailsPdf(mtaQuote, {
    hasAgreedToDeclaration,
  });

  const policyData = usePolicyData();

  const [user] = useUser();
  const isValidQuoteForTracking = !!mtaQuote && isQuoteSummaryValid(mtaQuote);
  usePageTracking(meta.meta_title, !!user?.isLoggedIn && isValidQuoteForTracking);

  useEffect(() => {
    if (!policyData) {
      // the policy needs to be in state for assumed data logic to work
      requestHandler(() =>
        policyClient.getPolicy(policyNumber).then((policy) => {
          dispatch({ type: UPDATE_POLICY, policyData: policy });
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, policyNumber, requestHandler]);

  const editAddressAction: MouseEventHandler = (e) => {
    e.preventDefault();
    updateMtaState({
      mtaInProgress: 'Edit address',
      editAddress: initialEditAddressMtaDetails,
      migratedFields: policyData?.migratedFields,
    });
    navigate(mtaRoutes.changeAddress(policyNumber));
  };

  const changeCarAction: MouseEventHandler = (e) => {
    e.preventDefault();
    updateMtaState({
      mtaInProgress: 'Change car',
      changeCar: initialVehicleDetails,
      migratedFields: policyData?.migratedFields,
    });
    navigate(mtaRoutes.changeCar(policyNumber));
  };

  const addDriverAction: MouseEventHandler = (e) => {
    e.preventDefault();
    updateMtaState({
      mtaInProgress: 'Add a driver',
      addDriver: initialAddDriverMtaDetails,
    });
    navigate(mtaRoutes.addDriver(policyNumber));
  };

  const editDriverAction = (driverId: string): MouseEventHandler => (e) => {
    e.preventDefault();
    updateMtaState({
      mtaInProgress: 'Edit a driver',
    });
    navigate(mtaRoutes.editDriver(policyNumber, driverId));
  };

  const [
    driverToRemove,
    setDriverToRemove,
  ] = useState<QuoteAdditionalDriverDetails | null>(null);

  const removeDriverAction = (
    driver: QuoteAdditionalDriverDetails
  ): MouseEventHandler => (e) => {
    e.preventDefault();
    setDriverToRemove(driver);
  };

  const accordionEntries: AccordionEntry[] = mtaQuote
    ? [
        {
          title: policy_details.heading,
          content: mtaQuote.tieringInfo ? (
            <YourCoverSection quote={mtaQuote} readOnly />
          ) : (
            <PolicyDetailsSection policy={mtaQuote} />
          ),
          onCollapse: trackAccordionCollapse(policy_details.heading),
          onExpand: trackAccordionExpand(policy_details.heading),
        },
        {
          title: optional_extras.heading,
          content: <OptionalExtrasSection policy={mtaQuote} />,
          onCollapse: trackAccordionCollapse(optional_extras.heading),
          onExpand: trackAccordionExpand(optional_extras.heading),
        },
        {
          title: excess.heading,
          content: <ExcessSection policy={mtaQuote} />,
          onCollapse: trackAccordionCollapse(excess.heading),
          onExpand: trackAccordionExpand(excess.heading),
        },
        {
          title: car_details.heading,
          content: (
            <CarDetailsSection policy={mtaQuote} changeCarAction={changeCarAction} />
          ),
          onCollapse: trackAccordionCollapse(car_details.heading),
          onExpand: trackAccordionExpand(car_details.heading),
        },
        {
          title: driver_details.heading,
          content: (
            <DriverDetailsSection
              policy={mtaQuote}
              changeAddressAction={editAddressAction}
              addDriverAction={addDriverAction}
              editDriverAction={editDriverAction}
              removeDriverAction={removeDriverAction}
            />
          ),
          onCollapse: trackAccordionCollapse(driver_details.heading),
          onExpand: trackAccordionExpand(driver_details.heading),
        },
      ]
    : [];

  const noPaymentChange = mtaQuote?.mtaPaymentAmount === 0;
  const ctaText = noPaymentChange ? cta_no_payment_change : cta_payment_change;

  const toPaymentOrConfirm = async (): Promise<void> => {
    if (noPaymentChange) {
      await requestHandler(
        () => mtaClient.confirm(policyNumber),
        'Confirming change, please wait.'
      );
      if (mtaQuote) {
        // This is still tracked as a 'purchase' event as the e-commerce funnel is complete
        trackPurchaseEvent(mtaQuote, policyNumber, 'MTA');
      }
      navigate(mtaRoutes.confirmation(policyNumber));
    } else {
      navigate(mtaRoutes.payment(policyNumber));
    }
  };

  useCheckoutTracking(2, mtaQuote, 'MTA');

  return (
    <Layout meta={meta} pageType="mtaAndRenewal">
      <LoadMtaQuoteWrapper policyNumber={policyNumber}>
        {mtaQuote && (
          <>
            {isLoading && <LoadingOverlay loadingMessage={loadingMessage || ''} />}
            <HeroPlain heading={hero.heading} subhead={hero.subheading} />
            <MtaQuoteSummarySection mtaQuote={mtaQuote} />
            {!isMtaSinglePaymentOnly(mtaQuote) && (
              <MtaMonthlyPaymentInfoBanner mtaQuote={mtaQuote} />
            )}
            {vehicleRegistrationMissing && (
              <MtaVehicleRegistrationRequiredSection
                id="vehicle-registration-required-section"
                onCtaClick={(e) => {
                  e.preventDefault();
                  navigate(mtaRoutes.changeCar(policyNumber), {
                    state: { clearManualLookup: true },
                  });
                }}
                getError={getError}
              />
            )}
            <form onSubmit={validateOnSubmit(toPaymentOrConfirm, scrollAndTrackError)}>
              <MtaDeclarationSection
                mtaQuote={mtaQuote}
                hasAgreed={hasAgreedToDeclaration}
                updateHasAgreed={(update) => {
                  setHasAgreedToDeclaration(update);
                  showValidation('hasAgreedToDeclaration');
                }}
                getError={getError}
              />
              <Grid>
                <StyledGridItem desktop={10} tabletLandscape={10}>
                  <PrimaryCta
                    type="submit"
                    cta={{
                      displayText: ctaText.text,
                      screenReaderText: ctaText.screenreader_text,
                      onClick: () =>
                        trackButtonClick('mtaCheckYourDetails', ctaText.text),
                    }}
                  />
                </StyledGridItem>
                <GridItem desktop={10} tabletLandscape={10}>
                  <SectionHeading id="policy-accordion-heading">{heading}</SectionHeading>
                  <Accordion
                    initiallyDisplayEntries={false}
                    accordionEntries={accordionEntries}
                  />
                  {driverToRemove && (
                    <ConfirmRemoveDriverModal
                      additionalDriver={driverToRemove}
                      onConfirm={() =>
                        requestHandler(async () => {
                          setDriverToRemove(null);
                          const updatedMta = await mtaClient.removeDriver(policyNumber, {
                            driverId: driverToRemove.driverId,
                            effectiveFromDate: mtaQuote.mtaEffectiveFromDate,
                          });
                          dispatch({ type: UPDATE_MTA_QUOTE, mtaQuote: updatedMta });
                        }, 'Removing driver, please wait')
                      }
                      onClose={() => setDriverToRemove(null)}
                    />
                  )}
                </GridItem>
              </Grid>
              <FormFooter
                contentColumns={{ desktop: 11 }}
                moveNextButton={{
                  text: ctaText.text,
                }}
                printButton={{
                  onClick: mtaQuote.tieringInfo ? generateTieredPdf : generatePdf,
                }}
              />
            </form>
          </>
        )}
      </LoadMtaQuoteWrapper>
    </Layout>
  );
};

export default withPolicyNumber(MtaCheckYourDetails);

import { SecondaryCta } from '@rsa-digital/evo-shared-components/components/Cta';
import { ActionLink } from '@rsa-digital/evo-shared-components/components/Link';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const ModalWrapper = styled.div`
  margin: ${spacing(2)} ${spacing(3)} ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(2)} ${spacing(7)} ${spacing(1)};
  `}

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(2)} ${spacing(8)} ${spacing(3)};
  `}
`;

export const ModalHeading = styled.h2`
  ${fonts.headingSmall}

  margin: 0;
`;

export const ModalBody = styled(RichText)`
  margin-top: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(2.5)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(1)};
  `}
`;

export const ConfirmCta = styled(SecondaryCta)`
  margin-right: ${spacing(2)};
`;

export const CtaContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  margin-top: ${spacing(4)};
`;

export const CancelActionLink = styled(ActionLink)`
  text-decoration: none;
`;
